<template>
  <div class="ls-background">
      <div v-if='!isLoading' class="ls-outer">
        <div class="ls-header">
          <div class="selected-level-ctr">Selected Level: <span class="selected-level-number">{{selectedLevelString}}</span></div>
          <v-btn :disabled='!selectedLevel' @click="loadLevel" color='primary'>Load Level</v-btn>
        </div>
        <div class='ls-inner'>
          <div class="levels-ctr">
            <div v-for='level in levels' @click="selectLevel(level)" :class="getLevelIconClass(level)" :key='level.id'>
              <div :class="getLevelLabelClass(level)">{{level.id}}</div>
            </div>
          </div>
          <div class="level-view">
            <Level
              :key='selectedLevelString'
              :levelId='selectedLevelString'
              :mapConfig='mapConfig'
            />
          </div>
        </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable radix */
import { mapActions } from 'vuex'
import Level from '@/components/level/Level.vue'
import config from '@/config.json'
import aws from '@/auth/aws'
import { routeNames } from '../../vars'

export default {
  name: 'LevelSelect',
  components: {
    Level
  },
  data() {
    return {
      isLoading: false,
      canvasKey: 1,
      userProfile: {},
      selectedLevel: null,
      levels: [
        {
          id: '1',
          isAccessible: false,
        },
        {
          id: '2',
          isAccessible: false,
        },
        {
          id: '3',
          isAccessible: false,
        },
        {
          id: '4',
          isAccessible: false,
        },
        {
          id: '5',
          isAccessible: false,
        },
        {
          id: '6',
          isAccessible: false,
        },
        {
          id: '7',
          isAccessible: false,
        },
        {
          id: '8',
          isAccessible: false,
        },
        {
          id: '9',
          isAccessible: false,
        },
        {
          id: '10',
          isAccessible: false,
        },
        {
          id: '11',
          isAccessible: false,
        },
        {
          id: '12',
          isAccessible: false,
        },
        {
          id: '13',
          isAccessible: false,
        },
        {
          id: '14',
          isAccessible: false,
        },
        {
          id: '15',
          isAccessible: false,
        },
        {
          id: '16',
          isAccessible: false,
        },
        {
          id: '17',
          isAccessible: false,
        },
        {
          id: '18',
          isAccessible: false,
        },
        {
          id: '19',
          isAccessible: false,
        },
        {
          id: '20',
          isAccessible: false,
        },
        {
          id: '21',
          isAccessible: false,
        },
        {
          id: '22',
          isAccessible: false,
        },
        {
          id: '23',
          isAccessible: false,
        },
        {
          id: '24',
          isAccessible: false,
        }
      ]
    }
  },
  methods: {
    ...mapActions(['showMessage', 'enableLoadingDialog', 'disableLoadingDialog']),
    async init() {
      let activityData = this.$api.storage.getItem('Activity')
      if (!activityData) {
        if (this.$api.user.isAuthenticated()) {
          try {
            activityData = await aws.getCurrentActivity().then((res) => res.data.activity)
          } catch (error) {
            this.showMessage('An error occurred while retrieving activity data. Loading default activity...')
          }
        }
        if (!activityData) {
          activityData = require('@/data/defaultActivityData.json').activity
        }
      }
      this.$api.storage.setItem('Activity', activityData)
      this.initLevels()
    },
    initLevels() {
      this.levels.forEach((l) => {
        const level = l
        const flag = this.$api.player.canAccessLevel(level.id)
        level.isAccessible = this.$api.player.canAccessLevel(level.id)
        if (flag && !this.selectedLevel) {
          this.selectLevel(level)
        }
      })
      if (!this.selectedLevel) {
        this.showMessage(`There no accessible levels. Please contact ${config.HELP_EMAIL}`)
      }
    },
    selectLevel(level) {
      if (level.isAccessible) {
        this.selectedLevel = JSON.parse(JSON.stringify(level))
        this.canvasKey += 1
      }
    },
    loadLevel() {
      this.rtr.push({ name: routeNames.LEVEL, params: { level: this.selectedLevelString } })
    },
    getLevelIconClass(level) {
      return {
        'level-icon': true,
        'enabled-icon': level.isAccessible,
        'selected-icon': this.selectedLevel ? this.selectedLevel.id === level.id : false
      }
    },
    getLevelLabelClass(level) {
      return {
        'icon-label': true,
        'no-select': true,
        'enabled-label': level.isAccessible
      }
    }
  },
  computed: {
    selectedLevelString() {
      return this.selectedLevel ? this.selectedLevel.id.toString() : '0'
    },
    mapConfig() {
      return {
        isDisabled: true,
        iconSize: 100,
        width: 454,
        height: 454
      }
    }
  },
  mounted() {
    this.isLoading = true
    this.enableLoadingDialog()
    this.init().then(() => {
      this.isLoading = false
      this.disableLoadingDialog()
    })
  }
}
</script>

<style lang='scss'>

$base-padding: 10px;
$border-color: var(--secondary);

.ls-background {
  width: 100%;
  height: 100%;
  background-color: #415054;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.ls-outer {
  width: 800px;
  display: flex;
  flex-direction: column;
}

.ls-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-bottom: $base-padding * 2;
}

.selected-level-ctr {
  color: white;
  font-weight: 700;
  margin-right: 2rem;
}

.selected-level-number {
  font-size: 1.5rem;
}

.ls-inner {
  display: flex;
  justify-content: space-between;
  min-width: 800px;
}

.levels-ctr {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.level-view {
  border: 1px black solid;
  width: 454px;
  height: 454px;
  position: relative;
  display: block;
}

.level-icons-ctr {
  display: flex;
  flex-wrap: wrap;
}

.level-icon {
  width: 65px;
  height: 60px;
  border: 2px solid grey;
  cursor: not-allowed;
  display: flex;
  align-items: center;
  justify-content: center;
}

.enabled-icon {
  border: 2px solid var(--secondary);
  cursor: pointer;
}

.selected-icon {
  background-color: var(--secondary);
}

.enabled-icon:hover {
  background-color: var(--secondary);
}

.icon-label {
  font-weight: 700;
  color: grey;
  font-size: 1.5rem;
  text-align: center;
}

.enabled-label {
  color: white;
}

.select-title {
  color: white;
  font-size: 1.6rem;
  font-weight: 500;
  margin: 2rem 0 1rem 0;
}

.add-level-button {
  background-color: #be5e35;
  width: 20px;
  height: 20px;
  padding: 1rem;
  font-size: 1.2rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

</style>
