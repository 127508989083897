<template>
  <div class="auth-background">
    <v-card class="auth-ctr">
      <LogoCard/>
      <p>Enter your email address below. You will be sent a link to reset your password.</p>
      <v-form v-model='isValid' ref='forgotPasswordForm' id='forgot-password-form' @submit.prevent='forgotPasswordAction; $emit("forgotPasswordAction", "Emitted")'>
        <v-text-field
          v-model='email'
          label='Email'
          required
          :rules='[this.formRules.generic, this.formRules.email]'
        ></v-text-field>
        <v-btn
          type='submit'
          form='forgot-password-form'
          color='primary'
          :disabled="!isValid"
          ref='submitButton'
        >Submit</v-btn>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import aws from '@/auth/aws'
import LogoCard from '@/components/LogoCard.vue'
import { routeNames } from '../../vars'

export default {
  name: 'ForgotPassword',
  components: {
    LogoCard
  },
  data() {
    return {
      isValid: false,
      email: ''
    }
  },
  methods: {
    ...mapActions(['showMessage', 'enableLoadingDialog', 'disableLoadingDialog']),
    forgotPasswordAction() {
      this.enableLoadingDialog()
      aws.startResetPassword(this.email)
        .then(() => {
          this.disableLoadingDialog()
          const message = `A verification code has been sent to: ${this.email}.`
          this.showMessage(message)
          this.rtr.push({ name: routeNames.RESET_PASSWORD })
        })
        .catch((error) => {
          this.disableLoadingDialog()
          const message = `An error occured. ${error.message}.`
          this.showMessage(message)
          this.$refs.forgotPasswordForm.reset()
        })
    }
  }
}
</script>

<style scoped>
  #forgot-password-form {
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
</style>
