<template>
  <div v-if='!isLoading' class="level-ctr">
    <DimensionError v-if='!isWindowSizeOK && !mapConfig.isDisabled'/>
    <span v-else>
      <TutorialDialog v-show='isTutorialActive'/>
      <Map v-if='hasMapConfigBeenSet'/>
      <span v-if='!mapConfig.isDisabled'>
        <ActionBar/>
        <LoadingDialog/>
        <ReflectionDialog />
      </span>
    </span>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Map from '@/components/Map.vue'
import config from '@/config.json'
import ReflectionDialog from '@/components/level/ReflectionDialog.vue'
import ActionBar from '@/components/level/ActionBar.vue'
import LoadingDialog from '@/components/LoadingDialog.vue'
import TutorialDialog from '@/components/level/TutorialDialog.vue'
import DimensionError from '@/components/DimensionError.vue'
import { routeNames } from '../../vars'

const defaultMapWidth = window.innerWidth
const defaultMapHeight = window.innerHeight
const defaultIconSize = 100
const defaultIsDisabled = false

export default {
  name: 'Level',
  props: {
    levelId: {
      type: String,
      required: false
    },
    mapConfig: {
      type: Object,
      required: false,
      default: () => ({
        width: defaultMapWidth,
        height: defaultMapHeight,
        iconSize: defaultIconSize,
        isDisabled: defaultIsDisabled
      })
    }
  },
  components: {
    Map,
    ActionBar,
    LoadingDialog,
    ReflectionDialog,
    TutorialDialog,
    DimensionError
  },
  data() {
    return {
      isLoading: false,
      isWindowSizeOK: true
    }
  },
  methods: {
    ...mapActions(['showMessage', 'initLevel', 'initLevelTutorial', 'enableLoadingDialog', 'disableLoadingDialog', 'updateMapElements']),
    onWindowResize(e) {
      if (!this.mapConfig.isDisabled) {
        this.updateMapElements({ width: e.currentTarget.innerWidth, height: e.currentTarget.innerHeight })
        this.updateWindowSizeStatus()
      }
    },
    updateWindowSizeStatus() {
      this.isWindowSizeOK = (config.dimensionLimit.height <= window.innerHeight) && (config.dimensionLimit.width <= window.innerWidth)
    }
  },
  computed: {
    ...mapGetters(['selectByID', 'elements', 'boardElements', 'logEvents', 'isTutorialActive', 'hasMapConfigBeenSet']),
    id() {
      return (this.levelId || this.$route.params.level)
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const levelId = vm.id
      if (!levelId || !vm.$api.player.canAccessLevel(levelId)) {
        next({ name: routeNames.LEVEL_SELECT })
        setTimeout(() => {
          // const base = 'You do not have access to'
          const message = 'Please select from the available levels.'
          // const message = levelId ? `${base} level ${levelId}.` : `${base} this level.`
          vm.showMessage(message)
        }, 1000)
      } else {
        next()
      }
    })
  },
  created() {
    window.addEventListener('resize', this.onWindowResize)
  },
  mounted() {
    this.enableLoadingDialog()
    this.isLoading = true
    const payload = { id: this.id, mapConfig: this.mapConfig }
    this.initLevel(payload)
      .then(() => {
        this.updateWindowSizeStatus()
        this.isLoading = false
      })
    this.disableLoadingDialog()
  },
  destroyed() {
    window.removeEventListener('resize', this.onWindowResize)
  }
}
</script>

<style scoped>
  .level-ctr {
    /* TODO: confirm css here */
    /* line-height: 0; */
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    background: url("../../assets/levelBackground.png") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
</style>
