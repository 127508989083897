<template>
  <canvas
    v-show="!isLoading"
    id="canvas"
    @mousemove='mouseMove'
    @contextmenu.prevent='mouseDownCtrl'
    @mousedown.alt='mouseDownAlt'
    @mousedown.exact='mouseDown'
    @mouseup='mouseUp'
    :width="mapWidth"
    :height="mapHeight"
  />
</template>

<script>
/**
 * An element map takes in elements and dimensions
 * It creates its own canvas, context, and xScale/yScale
 * It is also responsible for its own zoom events
 */

import * as has from 'lodash/has'
import * as d3 from 'd3'
import { logEventTypes } from '@/store/modules/logEvents'
import Pickup from '@/elements/Pickup'
import Delivery from '@/elements/Delivery'
import { mapActions, mapGetters } from 'vuex'
import { elementType, cursorStyles, actionType } from '../vars'

export default {
  name: 'Map',
  data() {
    return {
      isLoading: false,
      map: null,
      draggedEl: null,
      hoveredEl: null,
      startCell: null,
      activeLink: false,
      hasDisplayedLinkMessage: false,
      isSearching: false,
      highlightingElements: []
    }
  },
  methods: {
    ...mapActions([
      'addLogEvent',
      'setIsDraggingElement',
      'setDraggedElementPosition',
      'showMessage',
      'draw',
      'initMap',
      'removeElement',
      'beginLink',
      'setIsMakingLink',
      'linkElements',
      'setTestHasBeenRun',
      'createLoadImageElementFromExisting',
      'createLoadToggledElement',
      'checkTutorialRequiredAction',
      'addElement'
    ]),
    mouseMove(e) {
      const { y, x } = e
      const el = this.select(y, x)
      if (!this.isDraggingElement) {
        this.draggedEl = null
      }
      // If there is an active link
      if (this.activeLink) {
        // Update target coordinates (mouse element)
        this.activeLink.targetEl.coordinates = [y, x]
        // If user is hovering over an element
        if (el) {
          // Check if linkable and not hovering over self
          if (el.isLinkable && el.id !== this.activeLink.sourceEl.id) {
            // If so, update cursor to pointer
            this.map.style.cursor = cursorStyles.POINTER
          } else {
            // If unlinkable, show unallowed
            this.map.style.cursor = cursorStyles.NOT_ALLOWED
          }
          // If user not hovering over an element, make cursor default
        } else {
          this.map.style.cursor = cursorStyles.DEFAULT
        }
        // this.setTestHasBeenRun(false)
        this.draw()
        return
      }
      // If an element is being dragged
      if (this.draggedEl) {
        this.draggedEl.coordinates = [y, x]
      }
      // TODO: the rest of this block is inefficient
      if ((this.hoveredEl && el) && (el !== this.hoveredEl)) {
        this.hoveredEl.onMouseOut()
      }
      if (!el) {
        this.map.style.cursor = cursorStyles.DEFAULT
        if (this.hoveredEl) {
          // EXIT MOUSEOVER
          if (this.isSearching) {
            this.isSearching = false
            this.highlightingElements.forEach((elm) => {
              // set the state to not being hovered
              // eslint-disable-next-line
              elm.showHighlight = false
            })
            this.hoveredEl.showHighlight = false
            this.hoveredEl.originColor = false
            this.draw()
          }
          this.hoveredEl.onMouseOut()
          this.hoveredEl = null
          this.draw()
        }
        return
      }
      this.hoveredEl = el
      // If an element is found, set it as hovered element
      if (this.hoveredEl.isActionable && !this.isSimulationActive) {
        // ENTER MOUSEOVER
        if (this.hoveredEl instanceof Pickup || this.hoveredEl instanceof Delivery) {
          if (!this.isSearching) {
            // ensure we only search through elements once on mouseover
            this.isSearching = true
            if (this.hoveredEl instanceof Pickup) {
              const spec = this.hoveredEl.spec
              const color = this.hoveredEl.color
              const set = this.boardImageElements.filter((elm) => elm.type === 'delivery')
              this.highlightingElements = set.filter((elm) => (elm.data.acceptedTypes.includes(spec) || elm.data.acceptedTypes.length === 0) && (elm.data.acceptedColors.includes(color) || elm.data.acceptedColors.length === 0))
            }
            if (this.hoveredEl instanceof Delivery) {
              const spec = this.hoveredEl.data.acceptedTypes
              const color = this.hoveredEl.data.acceptedColors
              const set = this.boardImageElements.filter((elm) => elm.type === 'pickup')
              this.highlightingElements = set.filter((elm) => (spec.includes(elm.data.spec) || spec.length === 0) && (color.includes(elm.data.color) || color.length === 0))
            }
            this.highlightingElements.forEach((elm) => {
              // set the state for the element to being hovered
              // eslint-disable-next-line
              elm.showHighlight = true
            })
            this.hoveredEl.originColor = true
            this.hoveredEl.showHighlight = true
            this.draw()
          }
        }
        this.map.style.cursor = cursorStyles.POINTER
        this.hoveredEl.onMouseOver()
      } else {
        this.map.style.cursor = cursorStyles.NOT_ALLOWED
      }
      this.draw()
    },
    async mouseDownAlt(e) {
      if (!this.isSimulationActive) {
        const { y, x } = e
        const el = this.select(y, x)
        // If no element, return
        if (!el) return
        if (!el.isLinkable) {
          this.showMessage('This element is not linkable.')
          return
        }
        if (this.isElementPartOfExistingLink(el) && el.type === elementType.SIGNAL) {
          this.showMessage('Signals can only be linked to one other element.')
          return
        }
        if (!this.activeLink && el.type !== elementType.SIGNAL) {
          this.showMessage('Links must begin with a signal.')
          return
        }
        // If no link is active, begin link
        if (!this.activeLink) {
          // TODO: Check if element is part of existing link
          const res = await this.beginLink({ element: el, event: e })
          if (!res) return
          this.activeLink = res
          this.draw()
          return
        }
        // Check if element is being linked to itself
        if (this.activeLink.sourceEl.id === el.id) {
          this.showMessage('Elements cannot be linked to themselves.')
          return
        }
        if (this.activeLink.sourceEl.type === el.type) {
          this.showMessage('Elements cannot be linked to other elements of the same type.')
          return
        }
        if ([elementType.SEMAPHORE, elementType.SWITCH].includes(this.activeLink.sourceEl.type)
        && [elementType.SEMAPHORE, elementType.SWITCH].includes(el.type)) {
          this.showMessage('Semaphores cannot be connected to switches.')
          return
        }
        // TODO: check if element is part of existing link

        // If element is linkable, not itself, and activeLink, then link is being ended properly
        const mouse = this.activeLink.targetEl
        // Make the activeLink target the element that has been linked
        this.activeLink.targetEl = el
        // If linking is part of the tutorial, then handle accordingly
        if (this.shouldCheckTutorialRequiredAction) {
          const res = await this.checkTutorialRequiredAction({ type: actionType.FINISH_LINK, target: el.type })
          // const res = await this.beginLink({ element: el, event: e })
          if (!res) return
        }
        this.setIsMakingLink(false)
        if (!this.areLinksVisible && !this.hasDisplayedLinkMessage) {
          this.showMessage('Link created successfully, but it will not be displayed until the Link Indicator icon in the toolbar has been activated.')
          this.hasDisplayedLinkMessage = true
        }
        await this.addLogEvent({ type: logEventTypes.FINISH_LINK, element: el })
        this.setTestHasBeenRun(false)
        // NOTE: removal of stand-in mouse element must be done after new element is made target in link,
        // Otherwise the link will be deleted on mouse removal
        this.removeElement(mouse)
          // NOTE: this is being updated in the global store, so the local reference of activeLink can be made null
        this.activeLink = null
        this.draw()
      }
    },
    async mouseDownCtrl(e) {
      if (!this.isSimulationActive) {
        const el = this.select(e.y, e.x)
        if (!el) return
        if (el.isToggleable) {
          if (this.shouldCheckTutorialRequiredAction) {
            const res = await this.checkTutorialRequiredAction({ type: actionType.TOGGLE_ELEMENT, target: el.type })
            if (!res) return
          }
        el.onToggle()
        await this.addLogEvent({ type: logEventTypes.TOGGLE_ELEMENT, element: el })
        this.draw()
        }
      }
    },
    async mouseDown(e) {
      if ((e.which === 2 || e.which === 3) && this.draggedEl) return
      if (!this.isSimulationActive) {
        if (!this.areTutorialStepsEmpty) {
          const currActionType = this.currentTutorialStep.requiredAction.type
          if (!(currActionType === 'BUTTON_CLICK'
          || currActionType === 'ADD_ELEMENT'
          || currActionType === 'REMOVE_ELEMENT'
          || currActionType === 'TOGGLE_LINKS')) return
        }
        if (this.isTutorialActive && this.activeLink) return
        if (this.activeLink) {
          this.removeElement(this.activeLink)
          this.activeLink = null
          this.draw()
        }
        const el = this.select(e.y, e.x)
        if (!el) return
        if (el.isClickable) {
          el.onMouseDown()
          this.draw()
          return
        }
        if (el.isDraggable) {
          this.setIsDraggingElement(true)
          this.startCell = JSON.parse(JSON.stringify(el.cell))
          this.setDraggedElementPosition(JSON.parse(JSON.stringify(el.cell)))
          this.draggedEl = el
        }
      }
    },
    async mouseUp(e) {
      if (!this.isSimulationActive) {
        const { y, x } = e
        this.draw()
        if (!this.draggedEl) return
        this.setIsDraggingElement(false)
        if (this.hoveredEl.type === elementType.TRASH) {
          if (this.shouldCheckTutorialRequiredAction) {
            const res = await this.checkTutorialRequiredAction({ type: actionType.REMOVE_ELEMENT, target: this.draggedEl.type })
            // const res = await this.beginLink({ element: el, event: e })
            if (!res) return
          }
          const res = await this.removeElement(this.draggedEl)
          if (![elementType.MOUSE, elementType.LINK].includes(this.draggedEl.type) && !this.isSimulationActive) {
            this.addLogEvent({ type: logEventTypes.REMOVE_ELEMENT, startCell: this.startCell, element: this.draggedEl })
    }
          const numLinks = has(res, 'linkData.num') ? res.linkData.num : 0
          const currentElementType = has(res, 'elementData.type') ? res.elementData.type : 'element'
          let message
          if (numLinks > 0) {
            const linksModifier = numLinks > 1 ? 'links' : elementType.LINK
            message = `This ${currentElementType} and its ${numLinks} ${linksModifier} have been deleted.`
          } else {
            message = `This ${currentElementType} has been deleted.`
          }
          this.showMessage(message)
          this.setTestHasBeenRun(false)
          this.draw()
          return
        }
        if (!this.isOnTrack(y, x)) {
          this.returnDraggedEl()
          this.showMessage('Elements must be placed on path!')
          this.draw()
          return
        }
        if (this.isTrackOccupied(this.draggedEl, y, x)) {
          this.returnDraggedEl()
          this.showMessage('Cannot move to an occupied cell!')
          this.draw()
          return
        }
        if (this.draggedEl.isToolbarElement) {
          this.createLoadImageElementFromExisting({ existingElement: this.draggedEl, event: e })
          this.returnDraggedEl()
        } else {
          this.draggedEl.snapToGrid()
          this.addLogEvent({ type: logEventTypes.MOVE_ELEMENT, startCell: this.startCell, element: this.draggedEl })
          this.resetDraggedEl()
        }
        this.setTestHasBeenRun(false)
        this.draw()
      }
    },
    returnDraggedEl() {
      this.draggedEl.cell = this.startCell
      this.startCell = null
      this.setDraggedElementPosition(null)
      this.resetDraggedEl()
    },
    resetDraggedEl() {
      this.draggedEl = null
    }
  },
  computed: {
    ...mapGetters([
        'isOnTrack',
        'isTrackOccupied',
        'select',
        'mapWidth',
        'mapHeight',
        'hasMapConfigBeenSet',
        'elements',
        'boardImageElements',
        'isElementPartOfExistingLink',
        'isSimulationActive',
        'shouldCheckTutorialRequiredAction',
        'areLinksVisible',
        'isTutorialActive',
        'currentTutorialStep',
        'areTutorialStepsEmpty',
        'isDraggingElement'
      ])
  },
  mounted() {
    this.isLoading = true
    const mapD3 = d3.select('#canvas')
    this.map = mapD3.node()
    const ctx = this.map.getContext('2d')
    const payload = { mapD3, map: this.map, ctx }
    this.initMap(payload).then(() => {
      this.isLoading = false
    })
  },
}
</script>

<style scoped>
  #canvas {
    margin: 0;
    padding: 0;
  }
</style>
