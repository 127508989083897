// import * as has from 'lodash/has'
import config from '@/config.json'
import Vue from 'vue'
import * as d3 from 'd3'
import { logEventTypes } from '@/store/modules/logEvents'
import { actionType, elementType } from '../../vars'

async function getLevelTutorialData(levelName) {
  return require(`@/data/tutorials/tutorial_${levelName}.json`)
}

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

const defaultCurrentTutorialStepIndex = 0
const defaultIsActionRequired = true

/* eslint-disable no-shadow */
function getDefaultState() {
  return {
    levelTutorialData: {
      metadata: {},
      steps: []
    },
    isTutorialActive: false,
    isTutorialActionRequired: defaultIsActionRequired,
    currentTutorialStepIndex: defaultCurrentTutorialStepIndex,
  }
}

const state = getDefaultState()

const getters = {
  isTutorialActive: (state) => state.isTutorialActive,
  tutorialSteps: (state) => state.levelTutorialData.steps,
  areTutorialStepsEmpty: (state, getters) => getters.tutorialSteps.length === 0,
  currentTutorialStepIndex: (state) => state.currentTutorialStepIndex,
  currentTutorialStep: (state, getters) => {
    if (getters.areTutorialStepsEmpty) return { requiredAction: { type: [] } }
    return getters.tutorialSteps[getters.currentTutorialStepIndex]
  },
  isTutorialActionRequired: (state) => state.isTutorialActionRequired,
  isSimulationRequiredForTutorialStep: (state, getters) => getters.currentTutorialStep.requiredAction.type.includes('SIMULATION'),
  // Confirm that it's necessary to check if tht tutorial action is required
  shouldCheckTutorialRequiredAction: (state, getters) => getters.isTutorialActive && getters.isTutorialActionRequired,
  elementCoordinatesById: (state, getters) => (id) => {
    let x = 0
    let y = 0
    let imageElement = null
    if (id.includes('NEWEST')) {
      imageElement = getters.selectNewestByType(id.split('_')[1])
    } else {
      imageElement = getters.selectByID(id)
    }
    if (imageElement) {
      const coords = imageElement.getScreenCoordinates()
      y = coords[0]
      x = coords[1]
    }
    const htmlElement = d3.select(`#${id}`).node()
    if (htmlElement) {
      const { left, right, top, bottom } = htmlElement.getBoundingClientRect()
      x = (left + right) / 2
      y = (top + bottom) / 2
    }
    return { y, x }
  },
}

function getRequiredActionString(requiredActionType, requiredTarget) {
  if ((requiredActionType === actionType.BUTTON_CLICK) && (requiredTarget === elementType.TEST_BUTTON)) {
    return 'You must click the Test button in order to move forward.'
  }
  if ((requiredActionType === actionType.BUTTON_CLICK) && (requiredTarget === elementType.NEXT_BUTTON)) {
    return 'You must click the Next button to move forward.'
  }
  if ((requiredActionType === actionType.ADD_ELEMENT) && (requiredTarget === elementType.SIGNAL)) {
    return 'You must add a signal to the board to move forward.'
  }
  if ((requiredActionType === actionType.BEGIN_LINK) && (requiredTarget === elementType.SIGNAL)) {
    return 'You must begin the link  by alt-clicking on the signal.'
  }
  if ((requiredActionType === actionType.FINISH_LINK) && (requiredTarget === elementType.SEMAPHORE)) {
    return 'You must finish the link by alt-clicking on the semaphore.'
  }
  if ((requiredActionType === actionType.ADD_ELEMENT) && (requiredTarget === elementType.SEMAPHORE)) {
    return 'You must add a semaphore to the board to move forward.'
  }
  return 'You must complete the specified action to move forward.'
}

const actions = {
  setIsTutorialActionRequired: ({ commit }, flag) => {
    commit('setIsTutorialActionRequired', flag || defaultIsActionRequired)
  },
  checkTutorialRequiredAction: ({ dispatch, getters }, payload) => {
    if (!getters.isTutorialActionRequired) return true
    const { target, type } = payload
    if (!target || !type) return false
    const requiredActionType = getters.currentTutorialStep.requiredAction.type
    const requiredTarget = getters.currentTutorialStep.requiredAction.target
    if (requiredActionType === type && requiredTarget === target) {
      dispatch('advanceTutorialStep')
      return true
    }
    if (!getters.isSimulationActive) {
      dispatch('showMessage', getRequiredActionString(requiredActionType, requiredTarget))
    }
    return false
  },
  initLevelTutorial: async ({ commit, getters, dispatch }, levelName) => {
    if (!config.levelsWithTutorialsByName.includes(levelName)) return
    if (getters.areTutorialStepsEmpty) {
      const tutorialLevelData = await getLevelTutorialData(levelName)
      if (tutorialLevelData) {
        commit('setLevelTutorialData', tutorialLevelData)
      }
    }
    commit('setTutorialActive', true)
    dispatch('addLogEvent', { type: logEventTypes.TUTORIAL_STEP, step: getters.currentTutorialStep })
  },
  setTutorialActive: ({ commit }, flag) => {
    commit('setTutorialActive', flag || false)
  },
  advanceTutorialStep: async ({ commit, getters, dispatch }) => {
    if (getters.tutorialSteps.length - 1 === getters.currentTutorialStepIndex) {
      commit('setTutorialActive', false)
      commit('resetCurrentTutorialStepIndex')
      dispatch('addLogEvent', { type: logEventTypes.FINISH_TUTORIAL })
    } else {
      commit('setIsTutorialActionRequired', false)
      commit('incrementCurrentTutorialStepIndex')
      await sleep(config.defaultWait)
      commit('setIsTutorialActionRequired', true)
      dispatch('addLogEvent', { type: logEventTypes.TUTORIAL_STEP })
    }
  },
  destroyTutorial: ({ commit }) => {
    commit('destroyTutorial')
  }
}

const mutations = {
  setTutorialActive: (state, flag) => {
    state.isTutorialActive = flag
  },
  setIsTutorialActionRequired: (state, flag) => {
    state.isTutorialActionRequired = flag
  },
  setLevelTutorialData: (state, data) => {
    Vue.set(state, 'levelTutorialData', data)
  },
  incrementCurrentTutorialStepIndex: (state) => {
    state.currentTutorialStepIndex += 1
  },
  destroyTutorial(state) {
    Object.assign(state, getDefaultState())
  },
  resetCurrentTutorialStepIndex(state) {
    state.currentTutorialStep = defaultCurrentTutorialStepIndex
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
