/**
 * cursor type enum:
 * POINTER - hovering over element
 * DEFAULT - not hovering over any element
 * NOT_ALLOWED - cursor can not link
 */
 export const cursorStyles = {
    POINTER: 'pointer',
    DEFAULT: 'default',
    NOT_ALLOWED: 'not-allowed',
  }

  export const routeNames = {
    LANDING: 'Landing',
    LEVEL_SELECT: 'LevelSelect',
    LEVEL: 'Level',
    LOGIN: 'Login',
    CREATE_ACCOUNT: 'CreateAccount',
    FORGOT_PASSWORD: 'ForgotPassword',
    RESET_PASSWORD: 'ResetPassword',
    NOT_FOUND: 'NotFound',
    FORBIDDEN: 'Forbidden',
    JOIN_ACTIVITY: 'JoinActivity',
    ACTIVITY_OVERVIEW: 'ActivityOverview',
    PRIVACY_AGREEMENT: 'PrivacyAgreement'
  }

  export const urls = {
    CREATE_PLAYER: 'create-player',
    GET_ACTIVITY_DATA: 'get-activity-data',
    GET_CURRENT_ACTIVITY: 'get-current-activity',
    GET_PLAYER: 'get-player',
    SET_ACTIVITY_BY_ID: 'set-activity-by-id',
    GET_ACTIVITY_BY_CODE: 'get-activity-by-code',
    UPLOAD_LOG_DATA: 'upload-log-data',
    ADD_COMPLETED_LEVEL: 'add-completed-level',
  }

  export const actionType = {
    BUTTON_CLICK: 'BUTTON_CLICK',
    ADD_ELEMENT: 'ADD_ELEMENT',
    REMOVE_ELEMENT: 'REMOVE_ELEMENT',
    BEGIN_LINK: 'BEGIN_LINK',
    FINISH_LINK: 'FINISH_LINK',
    HIGHLIGHT_TRACK: 'HIGHLIGHT_TRACK',
    TOGGLE_ELEMENT: 'TOGGLE_ELEMENT',
    TOGGLE_LINKS: 'TOGGLE_LINKS',
    TEST: 'TEST',
    SUBMIT: 'SUBMIT',
    SIMULATION_SUCCESS: 'SIMULATION_SUCCESS',
    SIMULATION_FAILURE: 'SIMULATION_FAILURE'
  }

  export const elementType = {
    THREAD: 'thread',
    PICKUP: 'pickup',
    DELIVERY: 'delivery',
    SEMAPHORE: 'semaphore',
    TRASH: 'trash',
    CONDITIONAL: 'conditional',
    SWITCH: 'switch',
    PACKAGE: 'package',
    EXCHANGE: 'exchange',
    SIGNAL: 'signal',
    TRACK: 'track',
    LINK: 'link',
    MOUSE: 'mouse',
    LINKS_INDICATOR: 'linksIndicator',
    INTERSECTION: 'intersection',
    TEST_BUTTON: 'TEST_BUTTON',
    SUBMIT_BUTTON: 'SUBMIT_BUTTON',
    NEXT_BUTTON: 'NEXT_BUTTON'
  }

  export const resultType = {
    SUCCESS: 'SUCCESS',
    FAILURE: 'FAILURE'
  }

  export const operatingSystems = {
    MAC: 'Mac',
    WINDOWS: 'Windows',
    UNIX: 'Unix',
    LINUX: 'Linux'
  }

  export const browsers = {
    CHROME: 'Chrome',
    FIREFOX: 'Firefox',
    SAFARI: 'Safari',
    INTERNET_EXPLORER: 'Internet Explorer'
  }
